<template>
	<div class="ic-card">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>二次供水泵房管理 IC卡管理</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>门禁管理</li>
						<li>/</li>
						<li>IC门禁卡</li>
					</ul>
				</div>
			</div>
			<div>
				<el-input
					size="medium"
					style="width: 220px;"
					placeholder="卡号 / 使用者 / 关联泵房"
				></el-input>
				<el-button
					size="medium"
					class="ml-3"
					type="primary"
					icon="el-icon-search"
					>查询</el-button
				>
			</div>
			<div class="my-2">
				<vxe-table
					class="vxe-table-wrap"
					cell-class-name="vxe-table-cell "
					size="mini"
					height="700px"
					stripe
					highlight-hover-row
					border
					show-overflow
					keep-source
					auto-resize
					:data="cardList"
				>
					<vxe-table-column
						field="id"
						title="IC卡号"
						header-align="center"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<vxe-table-column
						field="status"
						title="状态"
						header-align="center"
						align="left"
						width="90"
						cell-type="string"
					>
					</vxe-table-column>
					<vxe-table-column
						field="userName"
						title="使用者"
						header-align="left"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<vxe-table-column
						field="pumpHouseNames"
						title="关联泵房"
						header-align="left"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<!-- <vxe-table-column
          field="area"
          title="片区"
          header-align="left"
          align="left"
          cell-type="string"
        >
        </vxe-table-column>

        <vxe-table-column
          field="addr"
          title="地址"
          header-align="left"
          align="left"
          cell-type="string"
        >
        </vxe-table-column> -->
				</vxe-table>
			</div>
			<el-pagination
				background
				layout="prev, pager, next, sizes,jumper,total"
				:total="1000"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
import sidebar from '../components/sidebar.vue'
import headerTop from '../components/header.vue'
export default {
	name: 'ic-card',
	components: {
		// Com,
		sidebar,
		headerTop,
	},
	data() {
		return {
			page5: {
				currentPage: 1,
				pageSize: 10,
				totalResult: 300,
			},
			cardList: [
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '0865055677',
					userName: '用户已删除',
					status: '正常',
				},
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '0865105379',
					userName: '用户已删除',
					status: '正常',
				},
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '123456789',
					userName: '用户已删除',
					status: '正常',
				},
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '2929058588',
					userName: '张一',
					status: '正常',
				},
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '3008238294',
					userName: '用户已删除',
					status: '正常',
				},
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '733075584',
					userName: '张一',
					status: '正常',
				},
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '736651392',
					userName: '张一',
					status: '正常',
				},
				{
					pumpHouseNames: '东海湾御花园:#1',
					id: '8650556774',
					userName: '周一',
					status: '正常',
				},
			],
		}
	},
}
</script>

<style lang="scss" scoped>
.ic-card {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		::v-deep {
			.vxe-table--body-wrapper {
				color: #fff;
				border: 1px solid rgba(255, 255, 255, 0.55);
				.vxe-cell {
					border-bottom: 1px solid rgba(255, 255, 255, 0.55);
					padding: 2px 5px;
					background: rgba(0, 0, 0, 0.35);
				}
			}
			.vxe-table--header-wrapper {
				border: 1px solid rgba(255, 255, 255, 0.55);
        border-bottom: none;
				background: rgba(0, 0, 0, 0.75);
				.vxe-cell {
					overflow: hidden;
					color: #fff;
					padding: 5px;
					text-align: center;
				}
			}
			.vxe-table--render-default .vxe-table--empty-block {
				display: none;
				visibility: hidden;
			}
			.vxe-table--empty-content {
				display: none;
			}
			.vxe-table--empty-placeholder {
				display: none;
			}
			.el-pagination__jump,
			.el-pagination__total {
				color: #fff;
				margin: 0 10px;
			}
			.el-pagination {
				text-align: right;
				margin-top: 20px;
			}
			.el-tabs--border-card,
			.el-tabs--border-card > .el-tabs__header {
				background: transparent;
				color: #fff;
			}
			.el-tabs--border-card > .el-tabs__header .el-tabs__item {
				color: #fff;
			}
			.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
				color: #d12;
			}
			.el-tree {
				color: #fff;
				background: rgba(0, 0, 0, 0.35);
			}
			.el-tree-node__content:hover {
				background-color: rgba(0, 0, 0, 0.75);
			}
			.el-tree-node:focus > .el-tree-node__content {
				background-color: rgba(0, 0, 0, 0.75);
			}
		}
	}
}
</style>
